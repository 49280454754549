import React, { useEffect, useState, useCallback } from 'react';
import "./claim.css";
import "../App.css";
import pulsedrop_abi from "../abi/pulsedrop_abi.json";
import distributor_abi from "../abi/distributor_abi.json"
import { ethers } from "ethers";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/confetti.png";
import { Link } from 'react-router-dom';
import { MerkleTree } from "merkletreejs";

function Claim() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [balance, setBalance] = useState("");
  const [showAirdrop, setShowAirdrop] = useState(true);
  const [showMemedrop, setShowMemedrop] = useState(false);
  const [senderLogs, setSenderLogs] = useState(null);
  const [droperLogs, setDroperLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  
  const [referrer, setReferrer] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [divisionalTitle, setDivisionalTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const pulsedropAddress = "0x448BA16B3b8cA8A96aeF7d54f4a1F24c5FfBae1C";
  const distributorAddress = "0x1a00B683267CF4BD8ed48207c1459312E640a271";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      const sign = tempProvider.getSigner();
      setProvider(tempProvider);
      setAccount(accounts[0]);
      setSigner(sign);
    } else {
      console.log("Please install Metamask.");
    }
  };

  const bal = useCallback(async () => {
    const contract = new ethers.Contract(
      pulsedropAddress,
      pulsedrop_abi,
      provider
    );

    const newBal = await contract.balanceOf(account);
    setBalance(newBal.toString());
  }, [account]);
  
  const { keccak256 } = ethers.utils;

  const verify = async () => {
    try {
      const response = await fetch('https://dropnation.wtf/assets/json/addressArray.json');
      if (response.ok) {
        const addressArray = await response.json();
        // const lowercaseAddresses = addressArray.map(addy => addy.toLowerCase());
        const leaves = addressArray.map(addr => keccak256(addr));
        const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
        const rootHash = merkleTree.getRoot().toString('hex');
        console.log(`addressArray Merkleroot: 0x${rootHash}`);
        const ownerProof = merkleTree.getHexProof(keccak256(account));
        console.log(`ownerProof: ${ownerProof}`);
        // addressArray.forEach((address) => {
        //   const proof = merkleTree.getHexProof(keccak256(address));
        //   console.log(`Address: ${address} Proof: ${proof}`);
        // });
        return ownerProof;
      } else {
        console.error('Failed to fetch addressArray:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching addressArray:', error);
    }
  };

  const calldata = async () => {
    try {
      const response = await fetch('https://dropnation.wtf/assets/json/stakersArray.json');
      if (response.ok) {
        const stakersArray = await response.json();
        const addresses = stakersArray.map((item) => item.address);
        const leaves = addresses.map((address) => ethers.utils.keccak256(address));
        const merkleTree = new MerkleTree(leaves, ethers.utils.keccak256, { sortPairs: true });
        const rootHash = merkleTree.getRoot().toString('hex');
        // console.log(`addressArray Merkle root: 0x${rootHash}`);
        const ownerProof = merkleTree.getHexProof(ethers.utils.keccak256(account));
        // console.log(`ownerProof: ${ownerProof}`);
        return ownerProof;
      } else {
        console.error('Failed to fetch stakersArray:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching stakersArray:', error);
    }
  };
  
  
  const claimAirdrop = async () => {
    let pf = await verify();
    const proof = pf;
    
    let validReferrer;
    // Check if referrer is empty
    if (referrer === '') {
      // Pass the account as the referrer
      validReferrer = account;
    } else {
      validReferrer = referrer;
    }
    console.log(proof);
    // // Pass the encoded proof and referrer to the smart contract function call
    const contractc = new ethers.Contract(distributorAddress, distributor_abi, signer);   
    console.log(validReferrer);  
    await contractc.claimAirdrop(proof, validReferrer);  
  };

  const claimMemedrop = async () => {
    let cd = await calldata();
    const proof = cd;
    console.log(proof);
    // Pass the encoded proof to the distributor
    const contractme = new ethers.Contract(distributorAddress, distributor_abi, signer); 
    await contractme.claimMemedrop(proof);  
  };

  function handleShowAirdrop() {
    setShowAirdrop(true);
    setShowMemedrop(false);
  }

  function handleShowMemedrop() {
    setShowMemedrop(true);
    setShowAirdrop(false);
  }

  const handleReferrerChange = (event) => {
    setReferrer(event.target.value);
  };
    
  useEffect(() => {
    initConnection();
  }, []);

  const disconnectWallet = () => {
    setAccount('');
  };
    
  useEffect(() => {
    setLoading(true);
    bal().then(() => setLoading(false)); // load the balance on mount

    const intervalId = setInterval(() => {
      setLoading(true);
      bal().then(() => setLoading(false)); // refresh the balance periodically
    }, 30000); // refresh every 30 seconds
    
    return () => clearInterval(intervalId); // clear the interval on unmount
  }, [bal]);

  useEffect(() => {
    const fetchData = async () => {
      if (!provider || !signer) return;
  
      const contractz = new ethers.Contract(distributorAddress, distributor_abi, signer);
      contractz.on("Claim", (claim, rewards, referrer, event) => {
        let data = {
          claim: claim.toString(),
          rewards: rewards.toString(),
          referrer: referrer.toString(),
          event,
        };
        let rec = claim.toString().toLowerCase();
        if (rec === account.toLowerCase()) {
          setSenderLogs(data);
        }
      });   

      contractz.on("Memedrop", (droper, rewards, event) => {
        let Data = {
          droper: droper.toString(),
          rewards: rewards.toString(),
          event,
        };
        let str = droper.toString().toLowerCase();
        if (str === account.toLowerCase()) {
          setDroperLogs(Data);
        }
      });  
    };  
  }, [provider, signer]); 

  return (
    <s.Screen image={cerberus}>
        <div className="topdiv">
        <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} style={{height: "30px"}}/></Link>

            {loading ? (
                  <div>Loading Balance...</div>
                ) : balance === "" ? (
                  <button className="button0" onClick={bal}>
                    Load Balance
                  </button>
                ) : (
                  <div style={{ color: "white" }}>
                    {(Number(balance) / 10 ** 18).toFixed()}{" "}
                    <span style={{ color: "lime" }}>$DROP</span>
                  </div>
                )}

          <div>
            {account == "" ? 
            <button title="Connect Wallet" onClick={initConnection} className={"button1"}>
              Connect Wallet
            </button>
            : (
              <p onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">
                ...{account.substring(account.length - 5)} 
              <span><img src={require(`../assets/images/icons/logout.png`)} style={{height: "25px"}}/></span> 
              </p> 
              )}
          </div>
        </div>
    <div className='line' />
<div className="centertable">
<span style={{cursor: "pointer"}}><img src={require(`../assets/images/ballongift.png`)} style={{height: "30vh"}} onClick={handleShowAirdrop}/></span>       
    {showAirdrop && (
                    <div className='maindiv' style={{height: "30vh"}}>
                    <p className='welcome'>Welcome to Dropnation!</p>
                      <input
                        className={"aperture"}
                        type="text"
                        placeholder="Enter referrer address"
                        value={referrer}
                        onChange={handleReferrerChange}
                        style={{height: "40px"}}
                      />
                    <button className='xbo-button bgreen brviolet' onClick={claimAirdrop}>
                    Claim
                    </button>
                    </div>
              )}          
      {showMemedrop && (
                <div className='maindiv' style={{height: "30vh", border: "solid skyblue"}}>
                <p className='welcome' style={{color:"gold", fontFamily: "Impact", fontSize: "40px"}}>Welcome Back!</p>
                <p className='welcome' style={{color:"skyblue"}}>Claim Your Memedrop!</p>
                  {/* <p
                    className={"aperture"}
                    type="text"
                    style={{height: "40px", backgroundColor: "white"}}>You have {0} tokens to claim.
                 </p> */}
                <button className='xbo-button bgreen' onClick={claimMemedrop} >
                Claim
                </button>
                </div>
          )}

  <span style={{cursor: "pointer"}}><img src={require(`../assets/images/ballonfrog.png`)} style={{height: "30vh"}} onClick={handleShowMemedrop}/></span>   
</div>

  <div className='notificationsbar'>
            {senderLogs && (
              <div className="stakenotify texttheme tgoldenrod">
                Airdrop claim successful!
                <span style={{ color: "white" }}>Recipient:</span>
                ...{senderLogs.claim.substring(senderLogs.claim.length - 12)}
                <span style={{ color: "white" }}>Pulsedrop Claimed:</span>{" "}
                {(senderLogs.rewards / 10 ** 18).toFixed(3)} $DROP
              </div>
            )}
            {droperLogs && (
              <div className="stakenotify texttheme tgoldenrod">
                Memedrop claim successful!
                <span style={{ color: "white" }}>Recipient:</span>
                ...{droperLogs.droper.substring(droperLogs.droper.length - 12)}
                <span style={{ color: "white" }}>Pulsedrop Claimed:</span>{" "}
                {(droperLogs.rewards / 10 ** 18).toFixed(3)} $DROP
              </div>
            )}
    </div>
    </s.Screen>
  );
};

export default Claim;