import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import * as s from "../styles/globalStyles";
import './stake.css';
import '../App.css';

import { ethers } from "ethers";
import Web3Modal from 'web3modal';

import abi from "../abi/abi.json";
import harvester_abi from "../abi/harvester_abi.json";

import { providerOptions } from '../tools/providerOptions';
import { formatNumber, truncateAddress, removeThousands } from '../tools/utils';
import Footer from './footer';

// Screen Resolution

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return isMobile ? children : null
}

// Web3Modal Constructor

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: false, // optional
  theme: "dark",
  providerOptions, // required
});

// Farming

function Stake () {
  const [account, setAccount] = useState();
  const [provider, setProvider] = useState();
  const [signer, setSigner] = useState(null);
  const [showFarm, setShowFarm] = useState(true);
  const [showHarvest, setShowHarvest] = useState(false);
  const [showUproot, setShowUproot] = useState(false);
  const [showRevoke, setShowRevoke] = useState(false);
  const [approveValue, setApproveValue] = useState("");
  const [farmValue, setFarmValue] = useState("");
  const [senderLogs, setSenderLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [isFarming, setIsFarming] = useState(false);
  const [totalStaked, setTotalStaked] = useState("");
  const [currentStake, setCurrentStake] = useState("");
  const [apy, setApy] = useState("");
  const [poolShares, setPoolShares] = useState("");
  // const [eraBlock, setEraBlock] = useState("");
  const [rewardClaim, setRewardClaim] = useState("");
  const [totalClaim, setTotalClaim] = useState("");
  const [rewardOwed, setRewardOwed] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [balance, setBalance] = useState(""); 
  
  // Mainnet

  const contractAddress = "0x09FD3D6e6889940Ca1158B9221309Bd69faFa32b";
  const harvesterAddress = "0x588f92C0120A786a60A197350f0b153aACFaa6B2"; 
  
  // Testnet
  
  //const contractAddress ="0x6858ad7d9f8fbaaf5c7365b07b7c713727b88a96";
  //const harvesterAddress ="0x1Df0216a41f99117bc15F0378B002f789319dE89";

  // Connect Wallet

  const initConnection = async () => {

    try {
      const provider = await web3Modal.connect();
      let tempProvider = new ethers.providers.Web3Provider(provider);

      const networkId = await tempProvider.provider.request({
        method: "net_version",
      });

      if (networkId !== "42161") {
        await tempProvider.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0xa4b1" }],
        });
        tempProvider = new ethers.providers.Web3Provider(provider);
      }

      const accounts = await tempProvider.listAccounts();
      const signer = tempProvider.getSigner();

      setProvider(tempProvider);

      if (accounts) setAccount(accounts[0]);

      setSigner(signer);
      setLoading(true);

    } catch (err) {

      switch (true) {
        case typeof err !== "undefined" && typeof err.message !== "undefined" && err.message.includes("User Rejected"):
          console.log("User rejected the request");
          break;
      
        case typeof err === "string" || err instanceof String:
          console.log("Modal closed by user");
          break;
      
        default:
          console.log("Something went wrong.");
          break;
      }

    }
  }

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("Account Changed", accounts);
        if (accounts) {
          setAccount(accounts[0]);
        }
      };
  
      const handleDisconnect = () => {
        console.log("Disconnect");
        web3Modal.clearCachedProvider();
        localStorage.clear();
        refreshState();
      };

      const handleNetworkChange = () => {
        console.log("Network Changed");
        web3Modal.clearCachedProvider();
        localStorage.clear();
        refreshState();
      };
  
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("disconnect", handleDisconnect);
      provider.on("chainChanged", handleNetworkChange);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("disconnect", handleDisconnect);
          provider.removeListener("chainChanged", handleNetworkChange);
        }
      };
    }
  }, [provider]);

  const refreshState = () => {
    setAccount();
    setProvider();
    setTotalStaked("");
    setTotalClaim("");
    setApy("");
    setPoolShares("");
    setCurrentStake("");
    setRewardOwed("");
    setRewardClaim("");
    setApproveValue("");
    setFarmValue("");
    setLoading(false);
  }

  const disconnectWallet = () => {
    web3Modal.clearCachedProvider();
    localStorage.clear();
    refreshState();
  };

  const bal = useCallback(async () => {    
    const contractERC = new ethers.Contract(
      contractAddress,
      abi,
      provider
    );
  
    const tempBal = await contractERC.balanceOf(account);
    setBalance(tempBal.toString());

  }, [account, provider]);

  useEffect(() => {

    if (!provider || !account ) return;

    bal(); // load the balance on mount

    const intervalIdBal = setInterval(() => {
      if (!provider || !account ) return;
        bal(); // refresh the balance periodically
    }, 30000); // refresh every 30 seconds

    return () => clearInterval(intervalIdBal); // clear the interval on unmount
    
  }, [provider, account, bal]);

  const maxAllowanceData = async () => {
    try {
      if (!provider || !account ) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      let ua = await contract.allowance(account, harvesterAddress);
      const userAllowance = ua/10**18;
      
      setFarmValue(formatNumber(userAllowance));

    } catch (err) {
      console.error(err);
    }
  };

// Verify if the user approved access to DROP tokens.

  const isApprovedRef = useRef(false);

  const fetchAllowanceData = async () => {

    try {

      if (!provider || !account ) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      let ua = await contract.allowance(account, harvesterAddress);
      const userAllowance = ua/10**18;

      const isUserApproved = userAllowance >= removeThousands(farmValue);

      isApprovedRef.current = isUserApproved;

    } catch (err) {

      console.error(err);

    }

  };

  // Approve access to DROP tokens

  const approve = async () => {

    if (!provider || !signer || isWaiting) return;

    try {
      setIsWaiting(true);
      const signer = provider.getSigner();
      const contractERC = new ethers.Contract(contractAddress, abi, signer);
      const approved = ethers.utils.parseEther(removeThousands(approveValue));
      const tx = await contractERC.approve(harvesterAddress, approved);
      const receipt = await tx.wait();
      console.log("Approval Succeeded");
      setRefreshTrigger(!refreshTrigger);
      return receipt; // Return the transaction receipt
    } catch (err) {

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsWaiting(false);
      return false;
    }
  };

  // Revoke access to DROP tokens

  const revoke = async () => {

    if (!provider || !signer || isRevoking) return;

    try {

      setIsRevoking(true);
      const signer = provider.getSigner();
      const contractERC = new ethers.Contract(
        contractAddress, 
        abi, 
        signer);
      const tx = await contractERC.approve(harvesterAddress, 0);
      await tx.wait();
      console.log("Revoke Succeeded");
      setRefreshTrigger(!refreshTrigger);

    } catch (err) {

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsRevoking(false);

    }
  };

  // Add additional DROP to farming.

  const addDrop = async () => {

    if (!provider || !signer || isFarming) return;

    try {

      setIsFarming(true);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(harvesterAddress, harvester_abi, signer); 
      const amount = ethers.utils.parseEther(removeThousands(farmValue));
      const tx = await contract.adddrop(amount);
      await tx.wait();
      console.log("Farm Succeeded");
      setRefreshTrigger(!refreshTrigger);

    } catch (err) {      

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsFarming(false);

    }   
  };

  // Withdraw all DROPS.

  const withdrawDrop = async () => {

    if (!provider || !signer || isWithdrawing) return;

    try {

      setIsWithdrawing(true);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        harvesterAddress, 
        harvester_abi, 
        signer); 
      const tx = await contract.withdrawdrop();
      await tx.wait();
      console.log("Withdraw Succeeded");
      setRefreshTrigger(!refreshTrigger);

    } catch (err) { 

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsWithdrawing(false);
      
    }   
  };
  
  // Claim all Rewards

  const claimRewards = async () => {

    if (!provider || !signer || isClaiming) return;

    try {

      setIsClaiming(true);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        harvesterAddress, 
        harvester_abi, 
        signer); 
      const tx = await contract.claim();
      await tx.wait();
      console.log("Claim Succeeded");
      setRefreshTrigger(!refreshTrigger);

    } catch (err) {

      if (err.message && err.message.includes("user rejected")) {
        console.log("Transaction Rejected by User");
      }
      setIsClaiming(false);

    }
  };

  const farmDrop = async () => {

    if (farmValue === '' || farmValue === '0') return;
    
    await fetchAllowanceData();

      if (isApprovedRef.current) {
        addDrop();
      } else {
        //setApproval(value);
        //const tx = await approve(); // Await the approve() function
        //if (tx) {
        //  addDrop();
        //}
      }
  };

  function handleShowFarm() {
    setShowFarm(true);
    setShowHarvest(false);
    setShowUproot(false);
    setShowRevoke(false);
  }

  function handleShowHarvest() {
    setShowHarvest(true);
    setShowFarm(false);
    setShowUproot(false);
    setShowRevoke(false);
  }

  function handleShowUproot() {
    setShowUproot(true);
    setShowHarvest(false);
    setShowFarm(false);
    setShowRevoke(false);
  }

  function handleShowRevoke() {
    setShowRevoke(true);
    setShowUproot(false);
    setShowHarvest(false);
    setShowFarm(false);
  }

  useEffect(() => {

    const fetchData = async () => {

      if (!provider || !signer) return;

      const contract = new ethers.Contract(harvesterAddress, harvester_abi, signer);
      contract.on("RewardClaimedByUser", (sender, rewards, event) => {
        let data = {
          sender: sender.toString(),
          rewards: rewards.toString(),
          event,
        };
        let rec = sender.toString().toLowerCase();
        if (rec === account.toLowerCase()) {
          setSenderLogs(data);
        }
      });      
  

      const divisor = ethers.utils.parseEther("100");

      const block = await provider.getBlock('latest');
      const blockTimeStamp = block.timestamp;

      const rps = await contract.rewardPerStamp();

      const tr = await contract.totalRewards();
      const tReward = ethers.utils.formatEther(tr);

      const ts = await contract.TotaldropSent();
      const tstaked = ethers.utils.formatEther(ts);
      setTotalStaked(Number(tstaked).toFixed());

      const tc = await contract.totalClaimedRewards();
      const tClaim = ethers.utils.formatEther(tc);
      setTotalClaim(Number(tClaim).toFixed(2));
  
      const rc = await contract.Claimants(account);
      const rclaimed = ethers.utils.formatEther(rc);
      setRewardClaim(Number(rclaimed).toFixed(2));


      const dropApy = (parseFloat(tClaim) / parseFloat(tReward) * 365) * 100;
      setApy(dropApy);
    
      const claimRewardsResult = await contract.claimRewards(account);
      const [eraAtBlock, dropSent, rewardsOwed] = claimRewardsResult;
  
      const dSent = ethers.utils.formatEther(dropSent);
      
      setCurrentStake(dSent);

      const period = blockTimeStamp - eraAtBlock;  
      const RoWed = ethers.utils.formatEther(rewardsOwed);
  
      const dropPoolShare = (parseFloat(dSent) * 100) / parseFloat(tstaked);
      setPoolShares(dropPoolShare);

      let revenue;

      /*
      console.log("RPS:", Number(rps));
      console.log("Periodo:", period);
      console.log("DropdSent:", Number(dSent));
      console.log("RoWed:", Number(RoWed));

      */

      if (rewardsOwed.toString() === "0") { 

        const reve = (Number(rps) * period * Number(dSent));
        const reven = Math.abs(reve);
        revenue = reven / divisor;
        setRewardOwed(Number(revenue).toFixed(3));

      } else {

        const reve = (Number(rps) * period * Number(dSent)) + Number(RoWed);
        revenue = reve / divisor;
        const rewardsAccrued = Math.abs(revenue);
        const roundedRewards = rewardsAccrued.toFixed(3);
        setRewardOwed(roundedRewards);

      }      
      
    };  
  
    fetchData();
  
    const intervalIdData = setInterval(() => {
      if (!provider || !signer) return;
      fetchData();
    }, 60000);
  
    return () => clearInterval(intervalIdData);
  
  }, [provider, signer, refreshTrigger, account]);  

  return (
    <s.Screen>
      <Desktop>
      <div className="header">
          <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} alt="Dropnation" style={{height: "60px"}}/></Link>           
          {loading && (
            <div style={{ color: "white" }}>
              {formatNumber((Number(balance) / 10 ** 18).toFixed(2))}
              <span style={{ color: "lime" }}> DROP</span>
            </div>
          )}
          <div>
            {account === undefined ? 
            <div title="Connect Wallet" onClick={initConnection} className={"button1"}>Connect Wallet</div>
            : (
              <div onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">{truncateAddress(account)}</div> 
              )}
          </div>
        </div> 
        <div className='info-container'>
          <div className='display-container'>
            <p className='smalltxt'>Total Staked</p>
            <p className='largetxt'>{formatNumber(totalStaked)} DROP</p>
          </div>
          <div className='display-container'> 
            <p className='smalltxt'>Total Claimed</p>
            <p className='largetxt'>{formatNumber(totalClaim)} ARB</p>         
          </div>
          <div className='display-container'> 
            <p className='smalltxt'>Pool Share Rate</p>
            <p className='largetxt'>{formatNumber(poolShares)} %</p>
          </div>
          <div className='display-container'>          
            <p className='smalltxt'>APY Rate</p>
            <p className='largetxt'>{formatNumber(apy)} %</p>
          </div>
        </div>
        <div className='dropper-container'>
          <div className='status-container'>
            <div className='display-container'>          
              <p className='smalltxt'>Current Stake</p>
              <p className='largetxt'>{formatNumber(currentStake)} DROP</p>
            </div>
            <div className='display-container'>  
              <p className='smalltxt'>Claimed Rewards</p>
              <p className='largetxt'>{formatNumber(rewardClaim)} ARB</p>
            </div>
            <div className='display-container'>  
              <p className='smalltxt'>Estimated Rewards</p>
              <p className='largetxt'>{formatNumber(rewardOwed)} ARB</p>
              <p className='nanotxt'>(Actual Rewards Pay More)</p>
            </div>
          </div>
          <div className="btn-group">
            <div className='btn-left-group' onClick={handleShowFarm}>Farm</div>
            <div className='btn-mid-group' onClick={handleShowHarvest}>Harvest</div>
            <div className='btn-mid-group' onClick={handleShowUproot}>Uproot</div>
            <div className='btn-right-group' onClick={handleShowRevoke}>Revoke</div>
          </div>
          {showFarm && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Step One - Approve access to DROP tokens</p>
              <form>
                  <div className="approve-box">
                    <input className={"inputform"} value={approveValue} onChange={(e) => setApproveValue(e.target.value)}/><div className="max-button" onClick={() => setApproveValue(formatNumber((Number(balance) / 10 ** 18).toFixed(2)))}>MAX</div>
                  </div>
                  <div onClick={approve} className={"buttonfarm"}>Approve</div>
              </form>
              <p className='stakesmalltxt'>Step Two - Start Farming</p>
              <form>
                  <div className="farm-box">
                    <input className={"inputform"} value={farmValue} onChange={(e) => setFarmValue(e.target.value)}/><div className="max-button" onClick={maxAllowanceData}>MAX</div>
                  </div>
                  <div onClick={farmDrop} className={"buttonfarm"}>Farm Now</div>
              </form>
            </div>
          )}
          {showHarvest && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Claim your rewards !!!</p>
              <form>
                  <div onClick={claimRewards} className={"buttonfarm"}>Harvest Now</div>
              </form>
            </div>
          )}
          {showUproot && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Withdraw all your DROP tokens</p>
              <form>
                  <div onClick={withdrawDrop} className={"buttonfarm"}>Withdraw Now</div>
              </form>
            </div>
          )}
          {showRevoke && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Revoke access to DROP tokens</p>
              <form>
                  <div onClick={revoke} className={"buttonfarm"}>Revoke</div>
              </form>
            </div>
          )}
        </div>
            <div className='notificationsbar'>
            {senderLogs && (
              <div className="stakenotify texttheme tgoldenrod">
                Reward claim successful!
                <span style={{ color: "white" }}>Recipient:</span>
                ...{senderLogs.sender.substring(senderLogs.sender.length - 12)}
                <span style={{ color: "white" }}>Rewards:</span>{" "}
                {(senderLogs.rewards / 10 ** 18).toFixed(3)} $ARB
              </div>
            )}
            </div>
          <Footer />
        </Desktop>
        <Mobile>
        <div className="header">
          <Link to="/" className={"textstyle"}><img src={require(`../logo_w_txt.png`)} alt="Dropnation" style={{height: "60px"}}/></Link>           
          {loading && (
            <div style={{ color: "white" }}>
              {formatNumber((Number(balance) / 10 ** 18).toFixed(2))}
              <span style={{ color: "lime" }}> DROP</span>
            </div>
          )}
          <div>
            {account === undefined ? 
            <div title="Connect Wallet" onClick={initConnection} className={"button1"}>Connect Wallet</div>
            : (
              <div onClick={disconnectWallet} className="wallet" title="Disconnect Wallet">{truncateAddress(account)}</div> 
              )}
          </div>
        </div> 
          <div className='info-container'>
            <div className='display-container'>
              <div className='mobile-container'>
                <p className='smalltxt'>Total Staked</p>
                <p className='largetxt'>{formatNumber(totalStaked)} DROP</p>
              </div>
              <div className='mobile-container'> 
                <p className='smalltxt'>Total Claimed</p>
                <p className='largetxt'>{formatNumber(totalClaim)} ARB</p>         
              </div>
              <div className='mobile-container'> 
                <p className='smalltxt'>Pool Share Rate</p>
                <p className='largetxt'>{formatNumber(poolShares)} %</p>
              </div>
              <div className='mobile-container'>          
                <p className='smalltxt'>APY Rate</p>
                <p className='largetxt'>{formatNumber(apy)} %</p>
              </div>
            </div>
          </div>
          <div className='dropper-container'>
              <div className='display-container-user'>
                <div className='mobile-container'>          
                  <p className='smalltxt'>Current Stake</p>
                  <p className='largetxt'>{formatNumber(currentStake)} DROP</p>
                </div>
                <div className='mobile-container'>  
                  <p className='smalltxt'>Claimed Rewards</p>
                  <p className='largetxt'>{formatNumber(rewardClaim)} ARB</p>
                </div>
                <div className='mobile-container'>  
                  <p className='smalltxt'>Estimated Rewards (*)</p>
                  <p className='largetxt'>{formatNumber(rewardOwed)} ARB</p>
                </div>
              </div>
              <div className="btn-group">
                <div className='btn-left-group' onClick={handleShowFarm}>Farm</div>
                <div className='btn-mid-group' onClick={handleShowHarvest}>Harvest</div>
                <div className='btn-mid-group' onClick={handleShowUproot}>Uproot</div>
                <div className='btn-right-group' onClick={handleShowRevoke}>Revoke</div>
            </div>
            {showFarm && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Step One - Approve access to DROP tokens</p>
              <form>
                <div className="approve-box">
                    <input className={"inputform"} value={approveValue} onChange={(e) => setApproveValue(e.target.value)} /><div className="max-button" onClick={() => setApproveValue(formatNumber((Number(balance) / 10 ** 18).toFixed(2)))}>MAX</div>
                </div>
                <div onClick={approve} className={"buttonfarm"}>Approve</div>
              </form>
              <p className='stakesmalltxt'>Step Two - Start Farming</p>
              <form>
                <div className="farm-box">
                <input className={"inputform"} value={farmValue} onChange={(e) => setFarmValue(e.target.value)}/><div className="max-button" onClick={maxAllowanceData}>MAX</div>
                </div>
                <div onClick={farmDrop} className={"buttonfarm"}>Farm Now</div>
              </form>
            </div>
          )}
          {showHarvest && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Claim your rewards !!!</p>
              <form>
                  <div onClick={claimRewards} className={"buttonfarm"}>Harvest Now</div>
              </form>
            </div>
          )}
          {showUproot && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Withdraw all your DROP tokens</p>
              <form>
                  <div onClick={withdrawDrop} className={"buttonfarm"}>Withdraw Now</div>
              </form>
            </div>
          )}
          {showRevoke && (
            <div className="stake-container">
              <p className='stakesmalltxt'>Revoke access to DROP tokens</p>
              <form>
                  <div onClick={revoke} className={"buttonfarm"}>Revoke</div>
              </form>
            </div>
          )}
            </div>
          <Footer />
        </Mobile>
    </s.Screen>
    
  );
};

export default Stake;